<template>
  <v-container
    fluid
    class="pt-3 pb-0"
  >
    <v-row class="text-left">
      <v-col
        cols="8"
        class="text-left pt-0 pb-0"
      >
        <div class="d-flex align-center">
          <Filters
            :value="localFilters"
            @input="handleInputFilter($event)"
            :loading="loading"
          />
          <Autocomplete
            :value="localFilters.phoneFilter"
            @input="handlePhoneFilter($event)"
            @changeNumber="handleChangeNumber($event)"
            item-value="id"
            item-text="phone"
            placeholder="поиск по номеру телефона"
            prepend-icon="mdi-database-search"
            mask="8 ### ### ## ##"
            url="/phone/autocomplete"
            class="pl-5 flex-grow-2"
          />
          <div class="disabledBtnCustom">
            <RelatedAdverts
              v-if="localFilters.phoneFilter || isPhoneNumberChanging"
              :phones="relatedPhone"
              @detail="$emit('detail', $event)"
            />
          </div>
          <v-select
            :value="localFilters.clientCategory"
            @input="handleClientCategoryFilter($event)"
            :items="ownerStatusesWithEmpty"
            label="Выберите категорию клиентов"
            prepend-icon="mdi-playlist-check"
            elevation="1"
            class="text-left pl-3"
            item-value="id"
            item-text="value"
          ></v-select>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Filters from '@/components/views/admin/clients/List/Controls/Filters'
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import controls from '@/components/mixins/controls/controls'
import RelatedAdverts from '@/components/views/admin/common/BasePhone/RelatedAdverts'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'

export default {
  mixins: [controls, serviceDataComputeds],
  name: 'Controls',
  components: { RelatedAdverts, Autocomplete, Filters },
  computed: {
    relatedPhone () {
      return this.localFilters.phoneFilter ? [{ phone: this.localFilters.phoneFilter }] : []
    }
  }
}
</script>

<style scoped>
  .disabledBtnCustom .theme--dark.v-btn.v-btn--disabled {
    color: #fff !important;
    opacity: 0.4;
  }
</style>
