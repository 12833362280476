<template>
  <div>
    <v-subheader
      v-if="title"
      class="caption"
    >
      {{title}}
    </v-subheader>
    <v-row>
      <v-col cols="6">
        <v-menu
          ref="fromDate"
          v-model="isOpenFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="C"
              v-model="dateFormatFrom"
              prepend-icon="mdi-calendar-range"
              readonly
              v-on="on"
              :dense="dense"
              :outlined="outlined"
              :hide-details="hideDetails"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="valueFormatFrom"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="isOpenFrom = !isOpenFrom"
            >
              Ок
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="clearFrom"
            >
              Очистить
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-menu
          ref="toDate"
          v-model="isOpenTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              ref="textTo"
              label="по"
              v-model="dateFormatTo"
              prepend-icon="mdi-calendar-range"
              readonly
              v-on="on"
              :dense="dense"
              :outlined="outlined"
              :hide-details="hideDetails"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="valueFormatTo"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="isOpenTo = !isOpenTo"
            >
              Ок
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="clearTo"
            >
              Очистить
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'DateRange',
  props: {
    dense: Boolean,
    outlined: Boolean,
    hideDetails: Boolean,
    value: Array,
    title: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      isOpenFrom: false,
      isOpenTo: false,
      dateFrom: '',
      dateTo: ''
    }
  },
  computed: {
    valueFormatFrom: {
      get () {
        const val = this.value
        return this.getValue(val[0])
      },
      set (value) {
        this.dateFrom = this.setValue(value, 0)
      }
    },
    dateFormatFrom () {
      return this.dateFrom
    },
    valueFormatTo: {
      get () {
        return this.getValue(this.value[1])
      },
      set (value) {
        this.dateTo = this.setValue(value, 1)
      }
    },
    dateFormatTo () {
      return this.dateTo
    }
  },
  watch: {
    value: {
      handler (value, oldVal) {
        if (value[0] === null && value[1] === null) {
          this.clearFrom()
          this.clearTo()
        }
      },
      deep: true
    }
  },
  methods: {
    clearFrom () {
      this.clearValue(0)
    },
    clearTo () {
      this.clearValue(1)
    },
    getValue (key) {
      let value = null
      if (key !== null) {
        let date = new Date(key * 1000)
        const [month, day, year] = date.toLocaleDateString('en-EN').split('/')
        value = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }
      return value
    },
    setValue (value, key) {
      const date = new Date(value)
      this.value[key] = date.getTime() / 1000
      return date.toLocaleDateString('ru-RU')
    },
    clearValue (index) {
      this.value[index] = null
      if (index === 0) {
        this.dateFrom = null
        this.isOpenFrom = false
      } else {
        this.dateTo = null
        this.isOpenTo = false
      }
      this.$emit('input', this.value)
    }
  },
  created () {
    if (this.value[0]) this.dateFrom = (new Date(this.value[0] * 1000)).toLocaleDateString('ru-RU')
    if (this.value[1]) this.dateTo = (new Date(this.value[1] * 1000)).toLocaleDateString('ru-RU')
  }
}
</script>

<style scoped>

</style>
