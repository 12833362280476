<template>
  <v-data-table
    :headers="localHeaders"
    :items="items"
    :options.sync="localOptions"
    @update:options="$emit('input', $event)"
    :server-items-length="itemsLength"
    :loading="loading"
    :footer-props="{itemsPerPageOptions:  [20,100,300]}"
    multi-sort
    @click:row="detail($event.id)"
  >
    <template #item="{ item }">
      <slot
        name="item"
        :item="item"
      ></slot>
    </template>
  </v-data-table>
</template>

<script>
import table from '@/components/mixins/table'
import { cleanClone } from '@/components/utils/common'

export default {
  mixins: [table],
  name: 'BaseTable',
  props: {
    headers: Array
  },
  computed: {
    localOptions: {
      get () {
        return this.options
      },
      set (val) {
        this.$emit('update:options', val)
      }
    },
    localHeaders () {
      let headers = cleanClone(this.headers)
      if (this.showSelect && this.headers[0].value !== 'checkbox') {
        headers.unshift({
          text: '',
          sortable: false,
          align: 'start',
          value: 'checkbox'
        })
      }
      return headers
    }
  }
}
</script>

<style scoped>
  .phone {
    min-width: 90px;
  }
  .date {
    width: 80px;
  }
</style>
