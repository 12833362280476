import _ from 'lodash'
import { deepEqual } from '@/components/utils/common'

export default {
  props: {
    items: {
      type: Object,
      default () {
        return {
          count: 0,
          models: []
        }
      }
    },
    buttonClass: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      options: {
        sortBy: [],
        page: 1,
        itemsPerPage: 20
      },
      loading: false,
      url: '',
      localItems: this.items
    }
  },
  computed: {
    getItems () {
      return this.localItems
    },
    getButtonClass () {
      return this.getItems?.count ? 'success' : 'primary'
    },
    canFillTable () {
      return true
    },
    /* todo: Разобраться с поведением options во всех таблицах и листах - есть лишние эмиты, перезаписи переменных и, наверняка, многое другое */
    localOptions: {
      get () {
        return this.options
      },
      set (val) {
        this.options = { ...this.options, ...val }
      }
    }
  },
  watch: {
    options: {
      async handler (newValue, oldValue) {
        if (Object.keys(newValue).length === Object.keys(oldValue).length && !deepEqual(oldValue, newValue)) {
          return this.debounceFillTable()
        }
      },
      deep: true
    },
    async value () {
      if (this.value) {
        return this.debounceFillTable()
      }
    }
  },
  methods: {
    debounceFillTable: _.debounce(async function () {
      return this.fillTable()
    }, 1000),
    async fillTable () {
      if (this.canFillTable) {
        this.loading = true
        this.localItems = []
        const response = await this.$store.dispatch('table/fillTable', {
          url: this.url,
          rawData: {
            options: this.options,
            filters: this.getFilters()
          }
        })
        if (response) {
          this.processItems(response)
        }
        this.loading = false
      }
      return true
    },
    getFilters () {
      return {}
    },
    processItems (response) {
      this.localItems = response
    }
  }
}
