<template>
  <tr
    :class="advertClassByStatus(item)"
    @click="detail"
  >
    <td>{{item.id}}</td>
    <td class="date">{{item.updated_at ? timestampToDatetime(item.updated_at) : ''}}</td>
    <td>
      <div
        v-for="phone in item.phones"
        :key="phone.phone"
        :class="phoneStatusClass(phone.status)"
        style="white-space:nowrap"
      >
        {{maskValue(phone.phone, '8 ### ### ## ##')}}
      </div>
    </td>
    <td>{{item.building ? formatValue('building.district_custom', item.building.district_custom) : ''}}</td>
    <td>{{item.building ? formatValue('building.subdistrict', item.building.subdistrict) : ''}}</td>
    <td>{{item.building ?item.building.mainBuildingAddress.street.name + ' ' + item.building.mainBuildingAddress.building_number : ''}}</td>
    <td>{{item.room_count}}</td>
    <td>{{formatValue('advert.flat_type', item.flat_type)}}
    </td>
    <td>{{item.floor}}</td>
    <td>{{item.building ? item.building.total_floors : ''}}</td>
    <td>{{item.building ? formatValue('building.building_material', item.building.building_material) : ''}}</td>
    <td>{{item.area_common}}</td>
    <td>{{item.building ? item.building.build_year : ''}}</td>
    <td>{{formatPrice(item.price)}}</td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import tabActions from '@/components/mixins/tabActions'

export default {
  name: 'ListItem',
  props: {
    item: Object
  },
  mixins: [formatters, tabActions],
  methods: {
    detail () {
      this.add('advert', 'detail', {
        title: null,
        id: null,
        client_id: null,
        agent_id: null,
        requestParams: {
          id: this.item.id
        }
      })
      if (this.$route.name !== 'advert') {
        this.$router.push({ name: 'advert' })
      }
    }
  }
}
</script>

<style scoped>

</style>
