<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :options="options"
          @update:options="$emit('input', $event)"
          :server-items-length="itemsLength"
          :loading="loading"
          dense
          class="elevation-1"
          multi-sort
        >
          <template v-slot:item="{ item, index }">
            <tr
              :class="classByStatus(item)"
              @click="detail(item.id)"
            >
              <td>{{ 1 + (options.page - 1) * options.itemsPerPage + index }}</td>
              <td>
                <div class="pa-2"
                     :class="classByCount(item)">{{ item.unviewedAdvertCount }}
                </div>
              </td>
              <td>{{ item.delay_until ? formatTodayDate(item.delay_until) : '' }}</td>
              <td>{{ item.created_at ? formatTodayDate(item.created_at) : '' }}</td>
              <td>{{ item.updated_at ? formatTodayDate(item.updated_at) : '' }}</td>
              <td>{{ item.username }}</td>
              <td>
                <div
                  v-for="phone in item.userPhones"
                  :key="phone.phone.phone"
                  class="phone mt-1"
                  :class="phoneStatusClass(phone.phone.status)"
                >
                  {{ maskValue(phone.phone.phone, '8 ### ### ## ##') }}
                  <div v-if="phone.phone.owner_status && phone.phone.owner_status.length">
                    <div
                      v-for="index in phone.phone.owner_status.length - 1"
                      :key="index"
                      class="empty-row"
                    >
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div
                  v-for="phone in item.userPhones"
                  :key="phone.phone.phone"
                  class="mt-1"
                >
                  <div v-if="phone.phone.owner_status && phone.phone.owner_status.length">
                    <div
                      v-for="status in phone.phone.owner_status"
                      :key="status"
                    >
                      {{ formatValue('phone.owner_status', status) }}
                    </div>
                  </div>
                  <div
                    v-else
                    class="empty-row"
                  >
                  </div>
                </div>
              </td>
              <td>{{ formatValue('user.impression', item.impression) }}</td>
              <td>{{ formatValue('user.success_rate', item.success_rate) }}</td>
              <td>{{ formatValue('user.deal_step', item.deal_step) }}</td>
              <td>{{ formatValue('users.agents', item.parent_id) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import headers from './header'
import formatters from '@/components/mixins/formatters'
import table from '@/components/mixins/table'

export default {
  mixins: [formatters, table],
  name: 'Table',
  data () {
    return {
      headers: headers
    }
  },
  methods: {
    classByStatus (item) {
      let className = ''
      if (this.opened.includes(item.id)) {
        className += ' selected-item'
      }
      return className
    },
    classByCount (item) {
      let result = null
      if (item.unviewedAdvertCount) {
        result = 'statusRed'
      }
      return result
    },
    ownerStatus (status) {
      let result = null
      if (status) {
        result = status.map(Number)
      }
      return result
    }
  }
}
</script>

<style scoped>
.phone {
  max-width: 90px;
}
.empty-row {
  height: 18px;
  width: 100%;
}
</style>
