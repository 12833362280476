export default [
  {
    text: '#',
    align: 'left',
    sortable: false,
    value: 'index',
    width: '10px'
  },
  {
    text: 'Новые',
    align: 'left',
    sortable: false,
    value: 'unviewedAdvertCount',
    width: '20px'
  },
  {
    text: 'Отложено до',
    align: 'left',
    sortable: true,
    value: 'delay_until',
    width: '60px'
  },
  {
    text: 'Создано',
    align: 'left',
    sortable: true,
    value: 'created_at',
    width: '70px'
  },
  {
    text: 'Касание',
    align: 'left',
    sortable: true,
    value: 'updated_at',
    width: '70px'
  },
  {
    text: 'Имя',
    align: 'left',
    sortable: true,
    value: 'username',
    width: '100px'
  },
  {
    text: 'Телефоны',
    align: 'left',
    sortable: false,
    value: 'userPhones',
    width: '80px'
  },
  {
    text: 'Категория',
    align: 'left',
    sortable: false,
    value: 'userPhones',
    width: '80px'
  },
  {
    text: 'Ст человека',
    align: 'left',
    sortable: true,
    value: 'impression',
    width: '60px'
  },
  {
    text: 'Ст адекв тр',
    align: 'left',
    sortable: true,
    value: 'success_rate',
    width: '60px'
  },
  {
    text: 'Ст лояльности',
    align: 'left',
    sortable: true,
    value: 'deal_step',
    width: '60px'
  },
  {
    text: 'Агент',
    align: 'left',
    sortable: true,
    value: 'parent_id',
    width: '100px'
  }
]
