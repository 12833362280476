<template>
  <v-dialog
    v-model="isOpen"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        small
        v-on="on"
      >
        <v-icon
          small
          class="mr-2"
        >
          mdi-tune
        </v-icon>
        фильтр
      </v-btn>
    </template>
    <v-container
      fluid
      class="white elevation-1 all-filter-block"
    >
      <div class="d-flex justify-end">
        <v-btn
          color="primary"
          icon
          @click="isOpen = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col cols="6">
          <DateRange
            v-model="filterLocalValues.updatedAt"
            title="Диапазон дат касания"
          />
        </v-col>
        <v-col cols="6">
          <DateRange
            v-model="filterLocalValues.createdAt"
            title="Диапазон дат создания"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="filterLocalValues.parentId"
            :items="agentsWithEmpty"
            item-value="id"
            item-text="value"
            label="Ответственный агент"
            prepend-icon="mdi-account-multiple"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="filterLocalValues.strategy"
            :items="strategyItems"
            item-value="id"
            item-text="value"
            label="Список клиентов"
            prepend-icon="mdi-account-cash"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="filterLocalValues.impression"
            :items="impressionItems"
            :label="$t('user.impression')"
            prepend-icon="mdi-playlist-check"
            item-value="id"
            item-text="value"
            elevation="1"
            class="text-left"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="filterLocalValues.successRate"
            :items="successRateItems"
            :label="$t('user.successRate')"
            prepend-icon="mdi-playlist-check"
            item-value="id"
            item-text="value"
            elevation="1"
            class="text-left"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="filterLocalValues.clientStatus"
            :items="clientStatusItems"
            :label="$t('user.clientStatus')"
            prepend-icon="mdi-playlist-check"
            item-value="id"
            item-text="value"
            elevation="1"
            class="text-left"
            return-object
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            small
            color="primary"
            class="mr-2"
            @click="handleFind"
          >Найти
          </v-btn>
          <v-btn
            small
            @click="handleClear"
          >Очистить
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import { cleanClone, deepEqual } from '@/components/utils/common'
import DateRange from '@/components/views/admin/advert/Common/Controls/DateRange'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import ClientsFilterList from '@/components/structures/filters/clientsFilterList'

export default {
  mixins: [serviceDataComputeds],
  name: 'Filters',
  components: { DateRange, Autocomplete },
  props: {
    value: Object,
    active: Boolean,
    loading: Boolean
  },
  data () {
    return {
      isOpen: false,
      filterLocalValues: null
    }
  },
  watch: {
    value: {
      handler () {
        if (!deepEqual(this.value, this.filterLocalValues)) {
          this.filterLocalValues = cleanClone(this.value)
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    agentsWithEmpty () {
      let agentsList = this.$store.getters['serviceData/optionsList']('users.agents')
      const profile = this.$store.getters['auth/getProfile']
      if (profile && this.$store.getters['auth/getRole'] !== 'admin') {
        agentsList = agentsList.filter(agent => agent.id === profile.id || !agent.id)
      }
      return agentsList
    },
    strategyItems () {
      return this.$store.getters['serviceData/optionsList']('clientListStrategy.strategy', false)
    },
    clientStatusItems () {
      return this.$store.getters['serviceData/optionsList']('user.client_status')
    }
  },
  methods: {
    loadFilters () {
      if (Object.entries(this.value).length) {
        const filters = Object.assign({}, this.value)
        for (const prop in filters) {
          if (filters.hasOwnProperty(prop) && this.filterLocalValues.hasOwnProperty(prop)) {
            this.filterLocalValues[prop] = filters[prop]
          }
        }
      }
    },
    handleFind () {
      this.$emit('input', cleanClone(this.filterLocalValues))
      this.isOpen = false
    },
    handleClear () {
      this.filterLocalValues = new ClientsFilterList()
      this.$emit('input', cleanClone(this.filterLocalValues))
    }
  }
}
</script>

<style scoped>
  .all-filter-block {
    position: relative;
    height: 65vh;
    overflow-x: hidden;
    overflow-y: visible;
  }
</style>
