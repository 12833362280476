export default [
  {
    text: 'ID',
    align: 'left',
    sortable: true,
    value: 'id',
    width: '80px'
  },
  {
    text: 'Дата',
    align: 'left',
    sortable: true,
    value: 'updated_at',
    width: '80px'
  },
  {
    text: 'Телефон',
    align: 'left',
    sortable: true,
    value: 'phone',
    width: '100px'
  },
  {
    text: 'Район',
    align: 'left',
    sortable: true,
    value: 'districtCustom',
    width: '100px'
  },
  {
    text: 'Подрайон',
    align: 'left',
    sortable: true,
    value: 'subdistrict',
    width: '100px'
  },
  {
    text: 'Адрес',
    align: 'left',
    sortable: true,
    value: 'address',
    width: '130px'
  },
  {
    text: 'Комнат',
    align: 'left',
    sortable: true,
    value: 'room_count',
    width: '45px'
  },
  {
    text: 'Тип',
    align: 'left',
    sortable: true,
    value: 'flat_type',
    width: '70px'
  },
  {
    text: 'Этаж',
    align: 'left',
    sortable: true,
    value: 'floor',
    width: '30px'
  },
  {
    text: 'Э-ть',
    align: 'left',
    sortable: true,
    value: 'total_floors',
    width: '30px'
  },
  {
    text: 'Мат стен',
    align: 'left',
    sortable: true,
    value: 'buildingMaterial',
    width: '80px'
  },
  {
    text: 'Площадь',
    align: 'left',
    sortable: true,
    value: 'area_common',
    width: '60px'
  },
  {
    text: 'Год',
    align: 'left',
    sortable: true,
    value: 'buildYear',
    width: '40px'
  },
  {
    text: 'Цена',
    align: 'left',
    sortable: true,
    value: 'price',
    width: '40px'
  }
]
